// api & service keys
export const JOBS_KEY = 'jobs'
export const JOBS_LOCAL_KEY = 'jobs_local'
export const BIG_PICTURE_KEY = 'bigPicture'
export const KOTISAARI_KEY = 'kotisaari'
export const DEFAULT_COUNTRY_KEY = 'KR'
export const BIZ_PLATFORM_KEY = 'biz_platform'
export const DAANGN_KEY = 'daangn'
export const DISCOVERY_KEY = 'discovery'
export const FAVORITE_KEY = 'favorite'
export const TERMS = 'terms'
export const IDENTIFICATION = 'identification'
export const EXPERIMENTATION = 'experimentation'
export const ADVERTISEMENT_KEY = 'advertisement'

// storage keys
export const SERVICE_KEY = 'JOBS' // Default setup
export const JOB_POST_READ = 'JOB_POST_READ'
export const CREATE_JOB_POST_FORM = 'CREATE_JOB_POST_FORM_V2'
export const COPY_JOB_POST_MESSAGE_SHOWN = 'COPY_JOB_POST_MESSAGE_SHOWN'
export const MY_APPLICATIONS_NUDGE_SHOWN = 'MY_APPLICATIONS_NUDGE_SHOWN'
export const RECENTLY_SEARCH_KEYWORDS = 'RECENTLY_SEARCH_KEYWORDS'
export const BUSINESS_FORM_VALUES = 'BUSINESS_FORM_VALUES'
export const WRITE_REVIEW_NUDGE_CLOSED = 'WRITE_REVIEW_NUDGE_CLOSED'
export const RECENTLY_JOB_POST_ADDRESS = 'RECENTLY_JOB_POST_ADDRESS'
export const EXPERIMENT_SEGMENTS = 'EXPERIMENT_SEGMENTS_V2'
export const ACQUISITION = 'ACQUISITION'
export const FEED_FILTER_STATE = 'FEED_FILTER_STATE_V2'
export const RECOMMEND_AD_PRICE_KEY = 'recommendAdPrice'
export const FAVORITE_NUDGE_SHOWN = 'FAVORITE_NUDGE_SHOWN'
export const FAVORITE_SUBSCRIPTION_NUDGE_SHOWN = 'FAVORITE_SUBSCRIPTION_NUDGE_SHOWN'
export const ACTIVITY_BADGE_ACQUIRE_GUIDE_CALLOUT = 'ACTIVITY_BADGE_ACQUIRE_GUIDE_CALLOUT'
export const ACTIVITY_BADGE_ACQUIRE_NOTIFICATION_CALLOUT = 'ACTIVITY_BADGE_ACQUIRE_NOTIFICATION_CALLOUT'
export const COUNT_SOMETHING = 'COUNT_' // + Some Event Name
export const PERSONAL_COMPANY_GUIDE_SHOWN = 'PERSONAL_COMPANY_GUIDE_SHOWN'
export const SHORTCUT_ONBOARDING_SHOWN = 'SHORTCUT_ONBOARDING_SHOWN'
export const APPLICATION_REJECT_MESSAGE = 'APPLICATION_REJECT_MESSAGE'
export const BUSINESS_CERTIFICATE_ADD_COMPANY_NAME_SHOWN = 'BUSINESS_CERTIFICATE_ADD_COMPANY_NAME_SHOWN'
export const MASKING_GUIDE_SHOWN = 'MASKING_GUIDE_SHOWN'
export const ADVERTISE_BIZ_PROFILE_NUDGE_SHOWN = 'ADVERTISE_BIZ_PROFILE_NUDGE_SHOWN_V2'
export const JOB_POST_CREATE_PERSONAL_TERMS_AGREED = 'JOB_POST_CREATE_PERSONAL_TERMS_AGREED'
export const MAIN_APPLICANT_TERMS_STORAGE_KEY = 'MAIN_APPLICANT_TERMS_BANNER_SHOWN'
export const APPLICANT_REVIEW_NUDGE_DELAYED_TIME = 'APPLICANT_REVIEW_NUDGE_DELAYED_TIME'
export const PERSONAL_JOB_ALARM_REGISTER_REJECTED = 'PERSONAL_JOB_ALARM_REGISTER_REJECTED'

// devtool storage key
export const DEVTOOL_RECENT_ROUTES = 'DEVTOOL_RECENT_ROUTES'

// 202409 추석 캠페인
export const CAMPAIGN_MARKETING_CHUSEOK_AUTHOR_202408_DONE = 'CAMPAIGN_MARKETING_CHUSEOK_202409_DONE'

// 202405 LGU 쿠폰연계 이벤트
export const CAMPAIGN_LGU_202405_SHOWN = 'CAMPAIGN_LGU_202405_SHOWN'
export const CAMPAIGN_LGU_202405_DONE = 'CAMPAIGN_LGU_202405_DONE'
// 202407 크로스셀링 캠페인 대상 등록 시간
export const CAMPAIGN_JOBS_PROMOTION_202407_SHOWN_TIME = 'CAMPAIGN_JOBS_PROMOTION_202407_SHOWN_TIME'
// 202407 크로스셀링 캠페인 참여 완료
export const CAMPAIGN_JOBS_PROMOTION_202407_DONE = 'CAMPAIGN_JOBS_PROMOTION_202407_DONE'

export const ADVERTISEMENT_CREATE_CONFIG = 'ADVERTISEMENT_CREATE_CONFIG'
// image File path
export const ARTICLE_PATH = 'article'
export const OPENED_BM_APPLICATION_IDS = 'OPENED_BM_APPLICATION_IDS'

// legacy
// export const FIRST_CREATE_MESSAGE_SHOWN = 'FIRST_CREATE_MESSAGE_SHOWN'
// export const ALARM_SET_MESSAGE_SHOWN = 'ALARM_SET_MESSAGE_SHOWN'
// export const ALARM_UNSET_MESSAGE_SHOWN = 'ALARM_UNSET_MESSAGE_SHOWN'
// export const CERTIFICATE_BUSINESS_PAGE_VISIBILITY = 'CERTIFICATE_BUSINESS_PAGE_VISIBILITY'
// export const CREATE_JOB_POST_ONBOARDING_SHOWN = 'CREATE_JOB_POST_ONBOARDING_SHOWN'
// export const REVIEW_OPEN_BANNER_CLICKED = 'OPEN_REVIEW_BANNER_CLICKED'
// export const MINIMUM_SALARY_AGREE_CALLOUT = 'MINIMUM_SALARY_AGREE_CALLOUT'
